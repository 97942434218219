import { MD5 } from 'object-hash';

/**
 * Takes a string in the format of "This is a {test}" and an object with
 * `{test: 'value'}` and returns the string with the value interpolated into it.
 * The result will be "This is a value".
 *
 * @param str a string to interpolate values into
 * @param obj the values object to interpolate into the string
 * @returns
 */
export function interpolate(str: string, obj: Record<string, any>): string {
  return str.replace(/\{(.*?)}/g, (match, p1) => (obj.hasOwnProperty(p1) ? String(obj[p1]) : match));
}

/**
 * The same as `interpolate`, but allows each part of the string
 * which is not a variable to be passed to a callback and be manipulated by the caller
 * before the string is put back together.
 *
 * @param str a string to interpolate values into
 * @param obj the values object to interpolate into the string
 * @param callback each part of the string which is not a variable will be passed to this callback
 * @returns
 */
export function chunkedInterpolate(str: string, obj: Record<string, any>, callback?: (str: string) => string): string {
  // Split the string into parts that are either a variable or not. Variables should include the brackets.
  return str
    .split(/(\{.*?\})/g)
    .filter(s => s != '')
    .map(s => {
      const part = s.trim(); // Need to trim the part in order for translation callbacks to work
      if (part.startsWith('{') && part.endsWith('}')) return s.replace(part, interpolate(part, obj));
      else if (callback) return s.replace(part, callback(part));
      return s;
    })
    .join('');
}

/**
 * Convert string to camelCase text.
 */
export function camelCase(str: string): string {
  if (!str || typeof str !== 'string') return str;
  return (removeNonWord(str)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, ' ') //convert all hyphens to spaces
    .replace(/\s[a-z]/g, s => s.toUpperCase()) //convert first char of each word to UPPERCASE
    .replace(/\s+/g, '') //remove spaces
    .replace(/^[A-Z]/g, s => s.toLowerCase())); //convert first char to lowercase
}

/**
 * Add space between camelCase text.
 */
export function unCamelCase(str: string): string {
  if (!str || typeof str !== 'string') return str;
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between lowercase and uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between consecutive uppercase letters followed by a lowercase letter
    .toLowerCase(); // Convert the entire string to lowercase
  // return str.replace(/([A-Z\xC0-\xDF])/g, ' $1').toLowerCase(); //add space between camelCase text
}

/**
 * UPPERCASE first char of each word.
 */
export function titleCase(str: string): string {
  if (!str || typeof str !== 'string') return str;
  return unCamelCase(str)
    .toLowerCase()
    .replace(/^[\wæøå]|\s[\wæøå]|\s/gi, s => s.toUpperCase());
}

/**
 * camelCase + UPPERCASE first char
 */
export function pascalCase(str: string): string {
  if (!str || typeof str !== 'string') return str;
  return camelCase(str).replace(/^[a-z]/, s => s.toUpperCase());
}

/**
 * UPPERCASE first char of each sentence and lowercase other chars.
 */
export function sentenceCase(str: string): string {
  if (!str || typeof str !== 'string') return str;
  // Replace first char of each sentence (new line or after '.\s+') to
  // UPPERCASE
  return unCamelCase(str)
    .toLowerCase()
    .replace(/(^\w)|\.\s+(\w)/gm, s => s.toUpperCase());
}

/**
 * Convert to lower case, remove accents, remove non-word chars and
 * replace spaces with the specified delimiter.
 * Does not split camelCase text.
 */
export function slugify(str: string, delimiter = '-'): string {
  if (!str || typeof str !== 'string') return str;
  return removeNonWord(str)
    .trim() //should come after removeNonWord
    .replace(/ +/g, delimiter) //replace spaces with delimiter
    .toLowerCase();
}

/**
 * Replaces spaces with hyphens, split camelCase text, remove non-word chars, remove accents and convert to lower case.
 */
export function hyphenate(str: string): string {
  if (!str || typeof str !== 'string') return str;
  str = unCamelCase(str);
  return slugify(str, '-');
}

/**
 * Replaces hyphens with spaces. (only hyphens between word chars)
 */
export function unhyphenate(str: string): string {
  if (!str || typeof str !== 'string') return str;
  return str.replace(/(\w)(-)(\w)/g, '$1 $3');
}

/**
 * Replaces spaces with underscores, split camelCase text, remove
 * non-word chars, remove accents and convert to lower case.
 */
export function underscore(str: string): string {
  if (!str || typeof str !== 'string') return str;
  str = unCamelCase(str);
  return slugify(str, '_');
}

/**
 * Remove non-word chars.
 */
export function removeNonWord(str: string): string {
  if (!str || typeof str !== 'string') return str;
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, '');
}

/**
 * Safely convert an object to a string. This will remove circular dependencies,
 * so the object might not be able to be converted back to a valid object.
 *
 * @param obj
 * @returns
 * @deprecated use [JsonUtils.objToString] instead.
 */
export function objToString(obj: any): string {
  if (obj == null || typeof obj !== 'object') return '' + obj;
  return JSON.stringify(obj, refReplacer());
}

/**
 * From https://stackoverflow.com/questions/10392293/stringify-convert-to-json-a-javascript-object-with-circular-reference/12659424
 * @deprecated use [JsonUtils.objToString] instead.
 */
function refReplacer() {
  const m = new Map();
  const v = new Map();
  let init: any = null;

  return function (field: any, value: any) {
    const p = m.get(this) + (Array.isArray(this) ? `[${field}]` : '.' + field);
    const isComplex = value === Object(value);

    if (isComplex) m.set(value, p);

    const pp = v.get(value) || '';
    const path = p.replace(/undefined\.\.?/, '');
    let val = pp ? `#REF:${pp[0] == '[' ? '$' : '$.'}${pp}` : value;

    !init ? (init = value) : val === init ? (val = '#REF:$') : 0;
    if (!pp && isComplex) v.set(value, path);

    return val;
  };
}

export function hash(obj: any) {
  return MD5(obj);
}

/**
 * Return an abbreviation of a string.
 *
 * @param str the string to abbreviate
 * @param max the maximum amount of characters to return
 * @returns the first letter of each word in the string
 */
export function abbreviate(str: string, max?: number) {
  if (!str || typeof str !== 'string') return str;
  let val = str
    .split(' ')
    .map(part => part.substring(0, 1))
    .join('');
  if (max) {
    val = val.substr(0, max);
  }
  return val;
}

/**
 * This will test the given error response and return a string representation of the error.
 * - If the error is a HTTPErrorResponse, it will try to extract the error message from the response.
 * - If the error is a string, it will return the string.
 * - If the error is an object, it will return a JSON representation of the object.
 * - If the error is null or undefined, it will return an empty string.
 * - If the error is anything else, it will return the error as a string.
 *
 * But if the error contains html, it will return the statusCode and statusText instead.
 *
 * @param err the error to convert to a string
 * @returns a string representation of the error
 */
export function errorToString(err: any): string {
  if (err == null) return '';

  const htmlRegex = /<.*?>/g;
  if ('error' in err) {
    // A detailed error is provided. Analyze and return the good parts
    if (typeof err.error === 'string' && !htmlRegex.test(err.error)) {
      // Happens when backend just returns a string
      return err.error;
    }
    if (err.error != null && typeof err.error === 'object') {
      if ('errors' in err.error) {
        // Usually a validation error. Backend returns this as an object of {type: message}
        const str = Object.values(err.error.errors)
          .filter(v => !!v)
          .join(', ');
        if (!htmlRegex.test(str)) return str;
      } else if ('Message' in err.error) {
        return JSON.stringify(err.error);
      }
    }
  }
  if ('message' in err && typeof err.message === 'string' && !htmlRegex.test(err.message)) {
    return err.message;
  }
  if ('statusText' in err && typeof err.statusText === 'string') {
    return err.statusText;
  }
  if (typeof err === 'object') {
    return JSON.stringify(err);
  }
  return err;
}

export function queryParamsToObject(str: string) {
  if (typeof str === 'string' && str != null && str.length > 0) {
    if (str.indexOf('?') > -1) str = str.split('?')[1];
    return str.split('&').reduce(
      (acc, p) => {
        const [key, value] = p.split('=');
        acc[key] = value;
        return acc;
      },
      {} as Record<string, any>,
    );
  }
  return {} as Record<string, any>;
}

export function lowerCase(str: string): string {
  if (typeof str === 'string' && str != null && str.length > 0) {
    str = str.toLowerCase();
  }
  return str;
}

export function upperCase(str: string): string {
  if (typeof str === 'string' && str != null && str.length > 0) {
    str = str.toUpperCase();
  }
  return str;
}

export function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
